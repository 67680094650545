<script setup>
// import _ from 'lodash'
import {
  // nextTick,
  computed,
  ref,
  reactive,
  // onMounted
} from 'vue'

//import { 
//	useStore 
//} from 'vuex'

//const store = useStore()
// const props = defineProps( [] )
// const emit = defineEmits( [] )
import topNavbar from '../components/top-navbar.vue'
import router from '@/router'
import fscLogo from '../../../public/fsc-logo.svg'

function logoClicked() {
  router.push( '/')
}

</script>

<template>
  <div id="outer-navbar" class="flex justify-content-center">

    <div id="navbar">
      <div id="inner-navbar" class="flex justify-content-between align-items-center">
        
        
        <div id="navbar-left" class="flex-none">
          <img
          :src="fscLogo"
          viewBox="0 0 300 73"
          width="300"
          height="73"
          alt="Food Service Containers logo"
          @click="logoClicked">
        </div>
        <top-navbar class="flex-grow-1" />
      </div>
    </div>
  </div>
    
</template>

<style lang="scss" scoped>
@import '../css/variables.scss';

#outer-navbar {
  margin-top: 30px;
  background-color: rgb(240, 250, 240);
  border-bottom: 1px solid #e2e8f0;

}

#navbar {

  width: 1200px;
}


#inner-navbar {
  padding: 30px 0;
  
  @media screen and (max-width: 1260px) {
    padding: 30px;
  }
}


#navbar-left {
  color: black;
  // color: $primaryColor;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.navbar-header {
  font-size: 30px;
}

.highlight-letter {
  color: black;
}
</style>