import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from 'axios'

export const useProductsStore = defineStore('products', () => {
  const categories = ref([])
  const products = ref([])
  const categoryNameFromRouteName = ( routeName ) => computed( ()=> {
    let category = categories.value.find( cat => 
      cat.name.toLowerCase().includes( routeName ) )

    if ( category ) {
      return category.name
    }
  } )



  async function getCategories() {
    let response = await axios.get( '/categories' )
    categories.value = response.data
  }
  
  async function getProducts( routeName ) {
    if ( Object.keys( categories.value ).length == 0 ) {
      await getCategories()
    }

    let category = categories.value.find( cat => 
      cat.name.toLowerCase().includes( routeName ) )

    if ( category ) {
      let response = await axios.get( `/products?category_id=${ category.id }` )
      products.value = response.data
    }
  }

  return {
    categories,
    products,
    
    categoryNameFromRouteName,
    
    getCategories,
    getProducts,
  }
})
