import { createApp } from 'vue/dist/vue.esm-bundler'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'

import '../css/theme.css'
import router from '@/router'
import App from './app.vue'

import VueGtag from 'vue-gtag'


const pinia = createPinia()

const app = createApp(App)
app.use(PrimeVue).use(router).use(pinia);

app.use(VueGtag, {
  config: {
    id: import.meta.env.FSC_GOOGLE_ANALYTICS_PROPERTY_ID
  }
})

app.mount('#app')