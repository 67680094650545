<script setup>
// import _ from 'lodash'
import { 
  // nextTick,
  computed,
  ref,
  reactive,
  // onMounted
} from 'vue'

import { storeToRefs } from 'pinia'
import router from '@/router'
const props = defineProps( [ 'categoryId' ] )

import { useProductsStore } from '@/store/products.js'
const productsStore = useProductsStore()

const { categories } = storeToRefs( productsStore )
const category = computed(()=>categories.value.find( cat => cat.id == props.categoryId ))

function selectCategory() {
  let categoryName = category.value.name.split( ' ' )[0].toLowerCase()
  router.push( `/category/${categoryName}` )
}

</script>

<template>
  <!-- <div> -->
    <div id="category-content" class="cursor-pointer" @click="selectCategory">
      <Card class="shadow-3">
        <template #title >
          <div class="underline cursor-pointer">
            {{ category.name }}
          </div>
        </template>
        <template #content>
            <div class="flex justify-content-center m-4 align-items-stretch">
              <img :src="'/images/category/' + category.image_link" class="category-img cursor-pointer"/>
            </div>
            <p class="m-0 text-center">
                {{ category.description }}
            </p>
        </template>
      </Card>

    </div>
  <!-- </div> -->
</template>

<style lang="scss" scoped>
@import '../css/variables.scss';

.p-card {
  width: 100%;
  border: 2px solid rgb(235, 235, 235, 0.8);
 
  &:hover {
    box-shadow: 0 0 5px 1px $primaryColor !important;
  }
}

#category-content {
  display: flex;
}
.category-img {
  height: 178px;

  @media screen and ( max-width: 699px ) {
    height: auto;
    width: 200px;
  }
}


</style>