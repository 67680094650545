<script setup>
// import _ from 'lodash'
import { 
  // nextTick,
  computed,
  ref,
  reactive,
  onMounted,
  onBeforeUnmount
} from 'vue'

import product from './product.vue'

import { storeToRefs } from 'pinia'
import { useProductsStore } from '@/store/products.js'
import router from '@/router'

const productsStore = useProductsStore()

const { products } = storeToRefs( productsStore )
const { categoryNameFromRouteName } = productsStore

const routeName = computed( () => router.currentRoute.value.params.routeName )

onMounted( () => {
  if ( routeName.value == null ) {
    return
  }
  
  productsStore.getProducts( routeName.value )

} )

const categoryName = categoryNameFromRouteName( routeName.value )

</script>

<template>
  <div id="products" class="p-2">
    <div id="products-header" class="pt-5 pb-5 font-bold text-3xl text-center3xl">{{  categoryName  }}</div>
    <product v-for="(prod, index ) in products" :routeName="routeName" :index="index" :key="prod.id"/>
  </div>
</template>

<style lang="scss" scoped>

#products {
  max-width: 600px;
}
</style>