<script setup>
// import _ from 'lodash'
import { 
  // nextTick,
  computed,
  ref,
  reactive,
  onMounted,
} from 'vue'

import router from '@/router'
import { storeToRefs } from 'pinia'
import { useProductsStore } from '@/store/products.js'
const productsStore = useProductsStore()
// const props = defineProps( [] )
// const emit = defineEmits( [] )

const { products } = storeToRefs( productsStore )

const routeName = computed( () => router.currentRoute.value.params.routeName )
const productId = computed( () => router.currentRoute.value.params.id )
const product = computed( () => products.value.find( prod => prod.id == productId.value ) )

onMounted( () => {
  if ( products.value.length == 0 ) {
    productsStore.getProducts( routeName.value )
  }
} )
</script>

<template>
  <div id="detail">
    <div id="detail-header" class="p-5 font-bold text-3xl text-center">
      {{ product && product.description }}
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>