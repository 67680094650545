<script setup>
// import _ from 'lodash'
import { 
  // nextTick,
  computed,
  ref,
  reactive,
  // onMounted
} from 'vue'
import router from '@/router'
import { storeToRefs } from 'pinia'
import { useProductsStore } from '@/store/products.js'

const props = defineProps( [ 'index', 'routeName' ] )
// const emit = defineEmits( [] )

const productsStore = useProductsStore()
const { products } = storeToRefs( productsStore )
const product = computed( ()=> products.value[ props.index ] )
const productDescription = computed( () => {
  if ( product.value && product.value.description.charAt(product.value.description.length - 1) == 's' ) {
    return product.value && product.value.description.substring(0,product.value.description.length-1) 
  } else {
    return product.value.description
  }
})

function productClicked() {
  return
  router.push( `${props.routeName}/product/${ product.value.id }`)
}

</script>

<template>
  <Card class="shadow-3 mb-4">
    <template #content>

      <div id="product" class="flex justify-content-between align-items-center gap-4 p-1" @click="productClicked">
        <div id="product_left">
          <img :src="product.image_links" alt="" width="200">
        </div>
        <div id="product_right" class="pt-3 pb-4 gap-4 flex-grow-1 flex justify-content-between" >
          <div class="flex flex-column justify-content-center">
            <div class="font-bold text-lg">
              {{ productDescription }}
            </div>
            <div>
              {{ product.name }}
            </div>
            <div>
              {{ product.count }} count
            </div>
          </div>
          <!-- <div class="flex flex-column justify-content-center" style="flex-basis: 30%;">
            <div>
            </div>
          </div> -->
        </div>
      </div>
    </template>
  </Card>
</template>

<style lang="scss" scoped>
@import '../css/variables.scss';

.p-card {
  &:hover {
    box-shadow: 0 0 5px 1px $primaryColor !important;
  }
}
</style>