<script setup>
// import _ from 'lodash'
import {
  // nextTick,
  computed,
  ref,
  reactive,
  // onMounted
} from 'vue'

import router from '@/router'

//import { 
//	useStore 
//} from 'vuex'

//const store = useStore()
// const props = defineProps( [] )
// const emit = defineEmits( [] )

const items = ref([
  { 
    label: 'Products',
    icon: 'pi pi-box',
    command: (event) => { router.push( { name: 'categories'} ) },
  },
  //{ 
  //   label: 'Contact Us',
  //   icon: 'pi pi-envelope',
  //   command: (event) => { router.push( { name: 'contact'} ) },
  // },{ 
  //   label: 'About Us',
  //   icon: 'pi pi-truck',
  //   command: (event) => { router.push( { name: 'about'} ) },
  // }
]);

const menuIndex = ref(1)
</script>

<template>
  <div id="navbar-container" class="flex justify-content-end">
    <Menubar :model="items" :selectedOptionIndex="menuIndex" />
  </div>
</template>

<style lang="scss" scoped>
#navbar-container {
  // border: 1px solid #000;
  
}

:deep(.p-menuitem-icon) {
  color: rgb(139, 199, 154);
}

:deep(.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link) {
  padding: 0.5rem 0.75rem 0.5rem 0.58rem;
  user-select: none;
}

:deep(.p-menuitem-text) {
  font-weight: bold;
}

:deep(.p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover),
:deep(.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content) {
  background-color: rgb(240, 250, 240);
}
</style>