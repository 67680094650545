import { createRouter, createWebHistory } from 'vue-router'

import landingPage from '@/components/landing.vue'
import categoriesPage from '@/components/categories.vue'
import productsPage from '@/components/products.vue'
import productDetailPage from '@/components/product-detail.vue'
import contactUsPage from '@/components/contact-us.vue'
import aboutUsPage from '@/components/about-us.vue'
import referralsPage from '@/components/referrals.vue'



const routes = [
  {
    path: '/',
    name: 'landing',
    component: landingPage,
  },
  {
    path: '/categories',
    name: 'categories',
    component: categoriesPage,
    path: '/categories',
    name: 'categories',
    component: categoriesPage,
  },
  {
    path: '/category/:routeName/product/:id',
    name: 'product-detail',
    component: productDetailPage,
  },
  {
    path: '/category/:routeName',
    name: 'category',
    component: productsPage,
  },
  {
    path: '/contact',
    name: 'contact',
    component: contactUsPage,
  },
  {
    path: '/about',
    name: 'about',
    component: aboutUsPage,
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: referralsPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router