<script setup>
import {
  // nextTick,
  computed,
  ref,
  reactive,
  onMounted
} from 'vue'

import { storeToRefs } from 'pinia'

import topStrip from '@/components/top-strip.vue'
import topHeader from '@/components/top-header.vue'

import { useProductsStore } from '@/store/products.js'
const productsStore = useProductsStore()
// const props = defineProps( [] )
// const emit = defineEmits( [] )

onMounted(() => {
  productsStore.getCategories()
})

</script>

<template>
  <div id="main-container">
    <top-strip />
    <top-header />
    <div class="flex justify-content-center">
      <div id="inner-router-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#main-container {
  overflow-x: hidden;
}

#router-container {
}

#inner-router-container {
  min-width: 80%;
  max-width: 1200px;

  >div {
    margin: 0 auto;
  }
}

</style>
