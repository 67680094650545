<script setup>
// import _ from 'lodash'
import {
  // nextTick,
  computed,
  ref,
  reactive,
  onMounted
} from 'vue'


import { storeToRefs } from 'pinia'
import category2 from './category.vue' //not sure why but category doesn't work :/

// import category2 from './category2.vue'

// const props = defineProps( [] )
// const emit = defineEmits( [] )

import { useProductsStore } from '../store/products.js'
const productsStore = useProductsStore()
const { categories } = storeToRefs( productsStore )

onMounted( () =>{
  productsStore.products = []
} )

</script>

<template>
  <div id="categories-container" class="flex flex-column">
    <div id="categories-header" class="p-5 font-bold text-3xl text-center">
      TAKE OUT CONTAINERS
    </div>
    <div id="categories-body" class="grid grid-nested">
      <div v-for="category in categories">
        <category2
          :key="category.id"
          :categoryId="category.id"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../css/variables.scss';

  #categories-header {
    
  }

  #categories-body {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 20px;
  gap: 30px;
  padding: 30px 80px;  
  grid-auto-rows: 1fr;

    @media screen and ( min-width: 1200px ) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and ( max-width: 699px ) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 30px 20px;
    }
  }

  </style>
  